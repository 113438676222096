import { createRouter, createWebHashHistory , RouteRecordRaw } from "vue-router";
import Home from "../views/main/index.vue";
import Customer from "../views/customer/index.vue";
import Map from "../views/map/index.vue";
import Address from "../views/address/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: Customer,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/map",
    name: "map",
    component: Map,
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "/address",
    name: "address",
    component: Address,
    meta: {
      keepAlive: true,
    },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
