export const QAList = [
  {
    img: "",
    cnt: "什么是肾脏移植？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾脏移植是将一个健康的肾脏从供体（活体或亡者）移植到患有终末期肾脏疾病（如尿毒症）的患者体内，以恢复其肾脏功能。在我国，肾脏移植有活体亲属供肾肾移植和公民逝世后器官捐献肾移植。",
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾脏移植的适应症是什么？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "适应症为各种原因（包括肾小球肾炎、糖尿病肾病、高血压肾病等）导致的终末期肾病等等。",
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "如何评估是否适合进行肾脏移植？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "医生会通过全面的身体检查、实验室检查（如血液和尿液测试）、影像学检查等来评估患者的整体健康状况和肾脏功能。",
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾移植前需要做哪些准备？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾移植前需要进行一系列评估（包括病史采集、体格检查、实验室检查影像学检查等）。同时，患者需要了解手术风险、术后注意事项等信息，并在进行肾移植手术前，做好一系列的准备工作，以确保手术的顺利进行和术后的恢复。",
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾移植后需要注意哪些事项？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾移植后，患者需要按时按量遵医嘱服用免疫抑制剂，监测生命体征（血压、心率、24h尿量等），定期随访，监测肝肾功能、电解质、血药浓度等指标。此外，保持良好的生活习惯、做好个人卫生防护、合理饮食和适量运动也是非常重要的。",
    user: true,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "6.肾脏移植的成功率是多少？",
    user: false,
    evaluate: false,
    gather: false,
  },
  {
    img: "",
    cnt: "肾脏移植的成功率通常以患者和移植肾的存活率来衡量。我国肾脏移植的成功率在不断提高。有报道指出，我国肾脏移植术后1年、3年肾存活率分别为97.9%、92.65%，达到了国际较先进水平。此外，一项基于中国肾脏移植科学登记系统（CSRKT）的真实世界研究显示，活体供肾（LD）肾脏移植受者术后5年的移植物存活率为93%，患者存活率为97.5%。。但具体情况因人而异，受多种因素（包括供体和受体的匹配程度、患者的整体健康状况、手术技术、以及术后的护理和管理等）影响。",
    user: true,
    evaluate: false,
    gather: false,
  },
];
