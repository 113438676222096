export const HospitalData = [
  {
    name: "北京协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110101",
    adname: "东城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A82Z2N",
    position: ["116.415768", "39.912682"],
  },
  {
    name: "北京医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110101",
    adname: "东城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A52E0B",
    position: ["116.415057", "39.903772"],
  },
  {
    name: "中日友好医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110105",
    adname: "朝阳区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A8370S",
    position: ["116.426883", "39.974097"],
  },
  {
    name: "中国医学科学院附属阜外医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090200",
    id: "B000A87GVW",
    position: ["116.351313", "39.925289"],
  },
  {
    name: "北京大学第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A7CNNE",
    position: ["116.380745", "39.931911"],
  },
  {
    name: "北京大学人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A5BA25",
    position: ["116.354215", "39.936961"],
  },
  {
    name: "北京大学第三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A7CGSS",
    position: ["116.360010", "39.982728"],
  },
  {
    name: "北京大学国际医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B000A887DZ",
    pcode: "110000",
    pname: "北京市",
    adcode: "110114",
    adname: "昌平区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090100",
    id: "B000A9Q0A3",
    position: ["116.263513", "40.101167"],
  },
  {
    name: "首都医科大学宣武医院",
    type: "三级甲等",
    level: "综合医院（侧重神经科学等）",
    parent: "B000A844ET",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A3EA6A",
    position: ["116.362275", "39.891298"],
  },
  {
    name: "首都医科大学附属北京朝阳医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110105",
    adname: "朝阳区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A845NT",
    position: ["116.453397", "39.925878"],
  },
  {
    name: "首都医科大学附属北京安贞医院",
    type: "三级甲等",
    level: "综合医院（心血管病优势明显）",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110105",
    adname: "朝阳区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B0FFFVGQPU",
    position: ["116.403379", "39.973153"],
  },
  {
    name: "首都医科大学附属北京友谊医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A11DA0",
    position: ["116.392125", "39.885153"],
  },
  {
    name: "首都医科大学附属北京佑安医院",
    type: "三级甲等",
    level: "专科医院（肝病等传染病）",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110106",
    adname: "丰台区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B0FFF33T81",
    position: ["116.355761", "39.866660"],
  },
  {
    name: "清华大学附属北京清华长庚医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110114",
    adname: "昌平区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090100",
    id: "B0FFF4BCKE",
    position: ["116.414943", "40.072551"],
  },
  {
    name: "中国人民解放军总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A9DPJ4",
    position: ["116.276578", "39.904572"],
  },
  {
    name: "中国人民解放军空军特色医学中心",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "B000A8VUX1",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B0FFLHMWBQ",
    position: ["116.302098", "39.920315"],
  },
  {
    name: "中国人民解放军火箭军特色医学中心",
    type: "三级甲等",
    level: "综合医院（火箭军特色相关医疗）",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B0FFJXXE78",
    position: ["116.372456", "39.956684"],
  },
  {
    name: "天津市第一中心医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "120000",
    pname: "天津市",
    adcode: "120104",
    adname: "南开区",
    citycode: "022",
    cityname: "天津市",
    typecode: "090101",
    id: "B0016063Z3",
    position: ["117.151896", "39.101076"],
  },
  {
    name: "天津医科大学总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "120000",
    pname: "天津市",
    adcode: "120101",
    adname: "和平区",
    citycode: "022",
    cityname: "天津市",
    typecode: "090101",
    id: "B00160DW6J",
    position: ["117.184610", "39.117381"],
  },
  {
    name: "泰达国际心血管病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "120000",
    pname: "天津市",
    adcode: "120116",
    adname: "滨海新区",
    citycode: "022",
    cityname: "天津市",
    typecode: "100100",
    id: "B0FFGIA3BA",
    position: ["117.701087", "39.032254"],
  },
  {
    name: "天津市胸科医院",
    type: "三级甲等",
    level: "专科医院（心胸疾病）",
    parent: "",
    pcode: "120000",
    pname: "天津市",
    adcode: "120101",
    adname: "和平区",
    citycode: "022",
    cityname: "天津市",
    typecode: "190000",
    id: "B0FFI8O0ZH",
    position: ["117.196951", "39.115886"],
  },
  {
    name: "河北医科大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "130000",
    pname: "河北省",
    adcode: "130105",
    adname: "新华区",
    citycode: "0311",
    cityname: "石家庄市",
    typecode: "090101",
    id: "B0137063F3",
    position: ["114.482117", "38.058064"],
  },
  {
    name: "河北医科大学第三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "130000",
    pname: "河北省",
    adcode: "130104",
    adname: "桥西区",
    citycode: "0311",
    cityname: "石家庄市",
    typecode: "090101",
    id: "B0137028B6",
    position: ["114.467980", "38.041085"],
  },
  {
    name: "中国人民解放军联勤保障部队北戴河康复疗养中心",
    type: "三级甲等",
    level: "疗养康复机构（兼具一定医疗能力）",
    parent: "",
    pcode: "130000",
    pname: "河北省",
    adcode: "130304",
    adname: "北戴河区",
    citycode: "0335",
    cityname: "秦皇岛市",
    typecode: "080402",
    id: "B0FFHWMUUK",
    position: ["119.436487", "39.807535"],
  },
  {
    name: "山西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140106",
    adname: "迎泽区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "190301",
    id: "BZCNP101UF",
    position: ["112.580674", "37.845995"],
  },
  {
    name: "山西省第二人民医院",
    type: "三级甲等",
    level: "综合医院（可能侧重肾病等部分专科）",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140106",
    adname: "迎泽区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090200",
    id: "B015F008D1",
    position: ["112.556525", "37.843484"],
  },
  {
    name: "山西医科大学第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140106",
    adname: "迎泽区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090101",
    id: "B015F0038D",
    position: ["112.555397", "37.853330"],
  },
  {
    name: "大同市第三人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140213",
    adname: "平城区",
    citycode: "0352",
    cityname: "大同市",
    typecode: "090101",
    id: "B016000B0U",
    position: ["113.287731", "40.075053"],
  },
  {
    name: "山西白求恩医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140105",
    adname: "小店区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090101",
    id: "B0FFKYMJBB",
    position: ["112.555225", "37.777302"],
  },
  {
    name: "山西省心血管病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140109",
    adname: "万柏林区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090205",
    id: "B015F0I8LN",
    position: ["112.525249", "37.871733"],
  },
  {
    name: "内蒙古包钢医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "150000",
    pname: "内蒙古自治区",
    adcode: "150203",
    adname: "昆都仑区",
    citycode: "0472",
    cityname: "包头市",
    typecode: "090101",
    id: "B01D80108C",
    position: ["109.824671", "40.652117"],
  },
  {
    name: "内蒙古医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "150000",
    pname: "内蒙古自治区",
    adcode: "150103",
    adname: "回民区",
    citycode: "0471",
    cityname: "呼和浩特市",
    typecode: "090101",
    id: "B01D7023H7",
    position: ["111.657528", "40.820503"],
  },
  {
    name: "中国医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210102",
    adname: "和平区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090101",
    id: "B00180CWQO",
    position: ["123.411350", "41.795344"],
  },
  {
    name: "大连医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210204",
    adname: "沙河口区",
    citycode: "0411",
    cityname: "大连市",
    typecode: "090101",
    id: "B019B0AOZE",
    position: ["121.570231", "38.882761"],
  },
  {
    name: "大连市友谊医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210202",
    adname: "中山区",
    citycode: "0411",
    cityname: "大连市",
    typecode: "090101",
    id: "B019B111J1",
    position: ["121.657033", "38.921100"],
  },
  {
    name: "鞍钢集团总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210302",
    adname: "铁东区",
    citycode: "0412",
    cityname: "鞍山市",
    typecode: "090101",
    id: "B0FFF5TZTT",
    position: ["123.011252", "41.120319"],
  },
  {
    name: "中国人民解放军北部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210103",
    adname: "沈河区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090101",
    id: "B0FFJU0NYC",
    position: ["123.440500", "41.769683"],
  },
  {
    name: "中国人民解放军北部战区空军医院",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210104",
    adname: "大东区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090207",
    id: "B001802FB0",
    position: ["123.472908", "41.793921"],
  },
  {
    name: "吉林大学白求恩第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01AF06SEY",
    pcode: "220000",
    pname: "吉林省",
    adcode: "220104",
    adname: "朝阳区",
    citycode: "0431",
    cityname: "长春市",
    typecode: "090101",
    id: "B01AF127AE",
    position: ["125.304855", "43.877282"],
  },
  {
    name: "哈尔滨医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01C30JIUR",
    pcode: "230000",
    pname: "黑龙江省",
    adcode: "230103",
    adname: "南岗区",
    citycode: "0451",
    cityname: "哈尔滨市",
    typecode: "150904",
    id: "B01C30JIWH",
    position: ["126.650518", "45.764741"],
  },
  {
    name: "哈尔滨医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01C30JJ8F",
    pcode: "230000",
    pname: "黑龙江省",
    adcode: "230103",
    adname: "南岗区",
    citycode: "0451",
    cityname: "哈尔滨市",
    typecode: "050100",
    id: "B0FFIQS9N6",
    position: ["126.619554", "45.694548"],
  },
  {
    name: "复旦大学附属中山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310104",
    adname: "徐汇区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00156TKFX",
    position: ["121.454965", "31.197318"],
  },
  {
    name: "复旦大学附属华山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310106",
    adname: "静安区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155KT4W",
    position: ["121.442932", "31.216111"],
  },
  {
    name: "复旦大学附属儿科医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310112",
    adname: "闵行区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090200",
    id: "B00155KP0U",
    position: ["121.394971", "31.139147"],
  },
  {
    name: "上海市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310109",
    adname: "虹口区",
    citycode: "021",
    cityname: "上海市",
    typecode: "141300",
    id: "B00153A582",
    position: ["121.489273", "31.253352"],
  },
  {
    name: "上海交通大学医学院附属瑞金医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00156QHJ2",
    pcode: "310000",
    pname: "上海市",
    adcode: "310101",
    adname: "黄浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155L9T9",
    position: ["121.467617", "31.211761"],
  },
  {
    name: "上海交通大学医学院附属新华医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00156QHJ2",
    pcode: "310000",
    pname: "上海市",
    adcode: "310110",
    adname: "杨浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155KEIX",
    position: ["121.517681", "31.273130"],
  },
  {
    name: "上海交通大学医学院附属仁济医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00155L1WO",
    pcode: "310000",
    pname: "上海市",
    adcode: "310101",
    adname: "黄浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B001570WJ3",
    position: ["121.484270", "31.233971"],
  },
  {
    name: "上海交通大学医学院附属儿童医学中心",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310115",
    adname: "浦东新区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B0FFFY3PJG",
    position: ["121.522661", "31.205738"],
  },
  {
    name: "上海市胸科医院",
    type: "三级甲等",
    level: "专科医院（心胸疾病）",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310104",
    adname: "徐汇区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090205",
    id: "B0G0CUYIP5",
    position: ["121.426043", "31.197695"],
  },
  {
    name: "上海市肺科医院",
    type: "三级甲等",
    level: "专科医院（肺部疾病）",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310110",
    adname: "杨浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155E7IJ",
    position: ["121.501899", "31.302544"],
  },
  {
    name: "中国人民解放军海军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310110",
    adname: "杨浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "140000",
    id: "B0FFJ597NX",
    position: ["121.529259", "31.306753"],
  },
  {
    name: "中国人民解放军海军军医大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310115",
    adname: "浦东新区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00156YLJ4",
    position: ["121.512572", "31.148012"],
  },
  {
    name: "中国人民解放军海军军医大学第三附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310110",
    adname: "杨浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "120302",
    id: "B0FFGB71UO",
    position: ["121.533767", "31.309050"],
  },
  {
    name: "苏州大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFL1OLPA",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320508",
    adname: "姑苏区",
    citycode: "0512",
    cityname: "苏州市",
    typecode: "050100",
    id: "B0FFFCVC0F",
    position: ["120.634399", "31.304555"],
  },
  {
    name: "无锡市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320213",
    adname: "梁溪区",
    citycode: "0510",
    cityname: "无锡市",
    typecode: "090101",
    id: "B01FE0II5H",
    position: ["120.323368", "31.540592"],
  },
  {
    name: "常州市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320402",
    adname: "天宁区",
    citycode: "0519",
    cityname: "常州市",
    typecode: "090101",
    id: "B020701JQM",
    position: ["119.960866", "31.779492"],
  },
  {
    name: "南京市第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320104",
    adname: "秦淮区",
    citycode: "025",
    cityname: "南京市",
    typecode: "090101",
    id: "B001907W0X",
    position: ["118.784263", "32.017145"],
  },
  {
    name: "中国人民解放军东部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFJRI5KZ",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320102",
    adname: "玄武区",
    citycode: "025",
    cityname: "南京市",
    typecode: "090101",
    id: "B0FFJRY861",
    position: ["118.808021", "32.040747"],
  },
  {
    name: "南京医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320106",
    adname: "鼓楼区",
    citycode: "025",
    cityname: "南京市",
    typecode: "090101",
    id: "B00190A90H",
    position: ["118.744998", "32.080323"],
  },
  {
    name: "徐州医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320311",
    adname: "泉山区",
    citycode: "0516",
    cityname: "徐州市",
    typecode: "160104",
    id: "B0FFGX2SW1",
    position: ["117.179368", "34.263154"],
  },
  {
    name: "浙江大学医学院附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B023B0929X",
    position: ["120.177825", "30.255920"],
  },
  {
    name: "浙江大学医学院附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B0FFG8HLTS",
    position: ["120.177439", "30.251172"],
  },
  {
    name: "浙江大学医学院附属邵逸夫医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B023B051V6",
    position: ["120.202351", "30.256376"],
  },
  {
    name: "浙江大学医学院附属儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330105",
    adname: "拱墅区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090200",
    id: "B023B06XYO",
    position: ["120.165734", "30.260224"],
  },
  {
    name: "浙江省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B023B0572T",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330105",
    adname: "拱墅区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B0HKDUYNP9",
    position: ["120.168551", "30.285243"],
  },
  {
    name: "温州医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330302",
    adname: "鹿城区",
    citycode: "0577",
    cityname: "温州市",
    typecode: "090101",
    id: "B024103ZQM",
    position: ["120.665318", "28.013566"],
  },
  {
    name: "宁波市医疗中心李惠利东部医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330212",
    adname: "鄞州区",
    citycode: "0574",
    cityname: "宁波市",
    typecode: "100100",
    id: "B0FFI5SAZM",
    position: ["121.562708", "29.853098"],
  },
  {
    name: "宁波市鄞州区第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330212",
    adname: "鄞州区",
    citycode: "0574",
    cityname: "宁波市",
    typecode: "190301",
    id: "B0FFH5APW9",
    position: ["121.552893", "29.808744"],
  },
  {
    name: "树兰（杭州）医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330105",
    adname: "拱墅区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B0FFG6IQYR",
    position: ["120.174522", "30.328786"],
  },
  {
    name: "中国人民解放军联勤保障部队第九〇三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330106",
    adname: "西湖区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "120302",
    id: "B0J3MHF2OE",
    position: ["120.116301", "30.246458"],
  },

  {
    name: "中国科学技术大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B022702OL9",
    position: ["117.267662", "31.847151"],
  },
  {
    name: "安徽医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B022702OL9",
    position: ["117.267662", "31.847151"],
  },
  {
    name: "安徽医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B02270IHE7",
    position: ["117.212410", "31.790188"],
  },
  {
    name: "福建医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350103",
    adname: "台江区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090101",
    id: "B024F05WSS",
    position: ["119.304419", "26.061337"],
  },
  {
    name: "福建医科大学附属协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090101",
    id: "B024F01LIN",
    position: ["119.304020", "26.077841"],
  },
  {
    name: "福建医科大学孟超肝胆医院",
    type: "三级甲等",
    level: "专科医院（肝胆疾病）",
    parent: "B0FFF5WFDJ",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090200",
    id: "B0FFGWONXL",
    position: ["119.279129", "26.085306"],
  },
  {
    name: "福建省立医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B024F04VN7",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "050100",
    id: "B0GRKU5LSZ",
    position: ["119.299381", "26.084133"],
  },
  {
    name: "厦门大学附属翔安医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350213",
    adname: "翔安区",
    citycode: "0592",
    cityname: "厦门市",
    typecode: "090100",
    id: "B02500QKUL",
    position: ["118.350162", "24.585555"],
  },
  {
    name: "厦门大学附属中山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350203",
    adname: "思明区",
    citycode: "0592",
    cityname: "厦门市",
    typecode: "090101",
    id: "B0250007B9",
    position: ["118.097611", "24.471519"],
  },
  {
    name: "厦门大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350203",
    adname: "思明区",
    citycode: "0592",
    cityname: "厦门市",
    typecode: "090101",
    id: "B025002CF9",
    position: ["118.088180", "24.450940"],
  },
  {
    name: "中国人民解放军联勤保障部队第九〇〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFJRGU1I",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090101",
    id: "B0FFK1YGP1",
    position: ["119.280085", "26.092136"],
  },
  {
    name: "中国人民解放军联勤保障部队第九一〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350502",
    adname: "鲤城区",
    citycode: "0595",
    cityname: "泉州市",
    typecode: "120302",
    id: "B0FFGALDA1",
    position: ["118.596073", "24.911835"],
  },
  {
    name: "厦门大学附属心血管病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350203",
    adname: "思明区",
    citycode: "0592",
    cityname: "厦门市",
    typecode: "120201",
    id: "B0FFJVF9IM",
    position: ["118.100926", "24.472134"],
  },
  {
    name: "江西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "120303",
    id: "B0FFF5OJLQ",
    position: ["115.887725", "28.691942"],
  },
  {
    name: "南昌大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "090101",
    id: "B031702KIL",
    position: ["115.910291", "28.686539"],
  },
  {
    name: "南昌大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "090101",
    id: "B031702VZZ",
    position: ["115.901957", "28.682490"],
  },
  {
    name: "山东大学齐鲁医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B02130WZ7F",
    position: ["117.018773", "36.656637"],
  },
  {
    name: "山东大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B02130WZ7F",
    position: ["117.018773", "36.656637"],
  },
  {
    name: "山东省立医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370104",
    adname: "槐荫区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "080603",
    id: "B0213014FF",
    position: ["116.982434", "36.657591"],
  },
  {
    name: "青岛大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370202",
    adname: "市南区",
    citycode: "0532",
    cityname: "青岛市",
    typecode: "090101",
    id: "B021403HO2",
    position: ["120.327228", "36.067916"],
  },
  {
    name: "山东省千佛山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "100105",
    id: "B0FFGQ30FC",
    position: ["117.053823", "36.648165"],
  },
  {
    name: "烟台毓璜顶医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370602",
    adname: "芝罘区",
    citycode: "0535",
    cityname: "烟台市",
    typecode: "090101",
    id: "B021704X7N",
    position: ["121.389746", "37.535769"],
  },
  {
    name: "临沂市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "371302",
    adname: "兰山区",
    citycode: "0539",
    cityname: "临沂市",
    typecode: "090101",
    id: "B021B09FNI",
    position: ["118.350793", "35.063762"],
  },
  {
    name: "聊城市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B027B016C9",
    pcode: "370000",
    pname: "山东省",
    adcode: "371502",
    adname: "东昌府区",
    citycode: "0635",
    cityname: "聊城市",
    typecode: "090100",
    id: "B0FFIXE6WT",
    position: ["115.977523", "36.469892"],
  },
  {
    name: "潍坊市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370705",
    adname: "奎文区",
    citycode: "0536",
    cityname: "潍坊市",
    typecode: "141202",
    id: "B0218030BR",
    position: ["119.129473", "36.701748"],
  },
  {
    name: "中国人民解放军联勤保障部队第九六〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370105",
    adname: "天桥区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B0FFJXWTMY",
    position: ["116.982558", "36.696924"],
  },
  {
    name: "中国人民解放军联勤保障部队第九七〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370613",
    adname: "莱山区",
    citycode: "0535",
    cityname: "烟台市",
    typecode: "190301",
    id: "B0IA34J80Q",
    position: ["121.443190", "37.514465"],
  },
  {
    name: "中国人民解放军海军第九七一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B02140VT8F",
    pcode: "370000",
    pname: "山东省",
    adcode: "370202",
    adname: "市南区",
    citycode: "0532",
    cityname: "青岛市",
    typecode: "071600",
    id: "B0JG3DWGX3",
    position: ["120.386284", "36.071360"],
  },
  {
    name: "郑州大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410103",
    adname: "二七区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0173080HG",
    position: ["113.646042", "34.752734"],
  },
  {
    name: "河南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01730KBLG",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "061210",
    id: "B0JRFHS5VA",
    position: ["113.681492", "34.771209"],
  },
  {
    name: "郑州人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B017305KKO",
    pcode: "410000",
    pname: "河南省",
    adcode: "410102",
    adname: "中原区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0IAJGR305",
    position: ["113.612287", "34.750256"],
  },
  {
    name: "郑州市第七人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410104",
    adname: "管城回族区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090200",
    id: "B01730K9C6",
    position: ["113.740263", "34.713582"],
  },
  {
    name: "河南中医学院第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0173046A3",
    position: ["113.674298", "34.759020"],
  },
  {
    name: "中国人民解放军联勤保障部队第九八八医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "190301",
    id: "B0IA3GLW9P",
    position: ["113.637196", "34.772961"],
  },
  {
    name: "阜外华中心血管病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "141203",
    id: "B0173084FO",
    position: ["113.680345", "34.772689"],
  },
  {
    name: "华中科技大学同济医学院附属同济医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B001B07JBE",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420104",
    adname: "硚口区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B0FFGTQYIR",
    position: ["114.262367", "30.579204"],
  },
  {
    name: "华中科技大学同济医学院附属协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420103",
    adname: "江汉区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B001B0IZPF",
    position: ["114.274723", "30.584057"],
  },
  {
    name: "武汉大学人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B001B06652",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420106",
    adname: "武昌区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B001B1C7BQ",
    position: ["114.300585", "30.535761"],
  },
  {
    name: "武汉大学中南医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420111",
    adname: "洪山区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090100|090200",
    id: "B0FFKCKC2N",
    position: ["114.396585", "30.536372"],
  },
  {
    name: "东风公司总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420303",
    adname: "张湾区",
    citycode: "0719",
    cityname: "十堰市",
    typecode: "060400|060100",
    id: "B02CF0QCF8",
    position: ["110.771031", "32.653481"],
  },
  {
    name: "中国人民解放军中部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420106",
    adname: "武昌区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B0FFJS7ZOP",
    position: ["114.344866", "30.531123"],
  },
  {
    name: "武汉亚洲心脏病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420103",
    adname: "江汉区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "120201",
    id: "B001B0JOUU",
    position: ["114.281771", "30.582584"],
  },
  {
    name: "宜昌市中心人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420503",
    adname: "伍家岗区",
    citycode: "0717",
    cityname: "宜昌市",
    typecode: "090101",
    id: "B0FFFGWCLN",
    position: ["111.306907", "30.684410"],
  },
  {
    name: "中南大学湘雅医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430105",
    adname: "开福区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B02DB00B1E",
    position: ["112.984047", "28.212209"],
  },
  {
    name: "中南大学湘雅二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430102",
    adname: "芙蓉区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "100103",
    id: "B02DB0VBWH",
    position: ["113.027619", "28.184897"],
  },
  {
    name: "中南大学湘雅三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430104",
    adname: "岳麓区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B02DB02J64",
    position: ["112.944798", "28.220483"],
  },
  {
    name: "湖南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430102",
    adname: "芙蓉区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B0H39RU13M",
    position: ["112.981993", "28.190395"],
  },
  {
    name: "益阳市中心医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430903",
    adname: "赫山区",
    citycode: "0737",
    cityname: "益阳市",
    typecode: "090100",
    id: "B02E1001PC",
    position: ["112.354620", "28.570175"],
  },
  {
    name: "郴州市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "431002",
    adname: "北湖区",
    citycode: "0735",
    cityname: "郴州市",
    typecode: "141201",
    id: "B02DF007E4",
    position: ["113.022814", "25.792694"],
  },
  {
    name: "南华大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430407",
    adname: "石鼓区",
    citycode: "0734",
    cityname: "衡阳市",
    typecode: "190301",
    id: "B0IA3ZSFYS",
    position: ["112.606648", "26.896262"],
  },
  {
    name: "常德市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430702",
    adname: "武陵区",
    citycode: "0736",
    cityname: "常德市",
    typecode: "190301",
    id: "B0FFGD4CIX",
    position: ["111.693638", "29.028146"],
  },
  {
    name: "岳阳市一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0HRSLRM6M",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430602",
    adname: "岳阳楼区",
    citycode: "0730",
    cityname: "岳阳市",
    typecode: "090101",
    id: "B02DA014B2",
    position: ["113.111718", "29.372735"],
  },
  {
    name: "湖南省儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430111",
    adname: "雨花区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090200",
    id: "B02DB03KPH",
    position: ["112.989376", "28.173845"],
  },
  {
    name: "中山大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140226E",
    position: ["113.291159", "23.126327"],
  },
  {
    name: "中山大学附属第三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440106",
    adname: "天河区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B0FFF5UY3V",
    position: ["113.341211", "23.134165"],
  },
  {
    name: "中山大学孙逸仙纪念医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B0FFH3TURK",
    position: ["113.255889", "23.109552"],
  },
  {
    name: "中山大学附属第五医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440402",
    adname: "香洲区",
    citycode: "0756",
    cityname: "珠海市",
    typecode: "090101",
    id: "B02F400ISX",
    position: ["113.573455", "22.298425"],
  },
  {
    name: "广东省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140UERJ",
    position: ["113.287272", "23.125087"],
  },
  {
    name: "广东省第二人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "100104",
    id: "B0014043F6",
    position: ["113.335513", "23.095585"],
  },
  {
    name: "南方医科大学南方医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440111",
    adname: "白云区",
    citycode: "020",
    cityname: "广州市",
    typecode: "100103",
    id: "B00140CK8U",
    position: ["113.329537", "23.187643"],
  },
  {
    name: "南方医科大学珠江医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140C1IU",
    position: ["113.268936", "23.081055"],
  },
  {
    name: "广州医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "141300",
    id: "B00140BBHL",
    position: ["113.262511", "23.112177"],
  },
  {
    name: "广州医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140UGDV",
    position: ["113.278609", "23.089880"],
  },
  {
    name: "广州医科大学附属第三医院",
    type: "三级甲等",
    level: "综合按医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440103",
    adname: "荔湾区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140I6VS",
    position: ["113.234177", "23.117800"],
  },
  {
    name: "广东省中医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140U136",
    position: ["113.256550", "23.118113"],
  },
  {
    name: "暨南大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440106",
    adname: "天河区",
    citycode: "020",
    cityname: "广州市",
    typecode: "070000",
    id: "B0J2TD3X8B",
    position: ["113.350217", "23.127149"],
  },
  {
    name: "广州市妇女儿童医疗中心",
    type: "三级甲等",
    level: "专科医院（妇女儿童）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090200",
    id: "B00140H3IU",
    position: ["113.253796", "23.119396"],
  },
  {
    name: "中国医学科学院阜外医院深圳医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440305",
    adname: "南山区",
    citycode: "0755",
    cityname: "深圳市",
    typecode: "090200",
    id: "B02F38MTDW",
    position: ["113.947663", "22.556961"],
  },
  {
    name: "深圳市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440303",
    adname: "罗湖区",
    citycode: "0755",
    cityname: "深圳市",
    typecode: "090101",
    id: "B02F37W9ER",
    position: ["114.128030", "22.557525"],
  },
  {
    name: "深圳市第三人民医院",
    type: "三级甲等",
    level: "综合医院（传染病等特色）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440307",
    adname: "龙岗区",
    citycode: "0755",
    cityname: "深圳市",
    typecode: "090101",
    id: "B02F38JF90",
    position: ["114.128750", "22.635393"],
  },
  {
    name: "中山市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "442000",
    adname: "中山市",
    citycode: "0760",
    cityname: "中山市",
    typecode: "090101",
    id: "B02F8008N5",
    position: ["113.383715", "22.525426"],
  },
  {
    name: "佛山市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440604",
    adname: "禅城区",
    citycode: "0757",
    cityname: "佛山市",
    typecode: "090101",
    id: "B02F501YDX",
    position: ["113.124749", "23.006370"],
  },
  {
    name: "中国人民解放军南部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00141RGH5",
    position: ["113.254855", "23.142608"],
  },
  {
    name: "中国人民解放军南部战区空军医院",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "120302",
    id: "B0FFF3V087",
    position: ["113.314114", "23.132953"],
  },
  {
    name: "广西壮族自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450103",
    adname: "青秀区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B030300767",
    position: ["108.327964", "22.802248"],
  },
  {
    name: "广西医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450103",
    adname: "青秀区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B03030044A",
    position: ["108.343151", "22.795874"],
  },
  {
    name: "广西医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450107",
    adname: "西乡塘区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B0FFHLD7SC",
    position: ["108.259811", "22.841281"],
  },
  {
    name: "广西中医药大学附属瑞康医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450102",
    adname: "兴宁区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B0303056U6",
    position: ["108.314762", "22.821812"],
  },
  {
    name: "中国人民解放军联勤保障部队第九二四医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450304",
    adname: "象山区",
    citycode: "0773",
    cityname: "桂林市",
    typecode: "090101",
    id: "B0FFJX8ZRE",
    position: ["110.293958", "25.265328"],
  },
  {
    name: "中国人民解放军联勤保障部队第九二三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450203",
    adname: "鱼峰区",
    citycode: "0772",
    cityname: "柳州市",
    typecode: "130104",
    id: "B03040OIG0",
    position: ["109.429093", "24.282416"],
  },
  {
    name: "海南医学院第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "460000",
    pname: "海南省",
    adcode: "460106",
    adname: "龙华区",
    citycode: "0898",
    cityname: "海口市",
    typecode: "090101",
    id: "B03820OY4N",
    position: ["110.300158", "19.979264"],
  },
  {
    name: "海南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "460000",
    pname: "海南省",
    adcode: "460105",
    adname: "秀英区",
    citycode: "0898",
    cityname: "海口市",
    typecode: "090101",
    id: "B03820OIDK",
    position: ["110.287710", "20.004771"],
  },
  {
    name: "重庆医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170L57Q",
    position: ["106.512621", "29.530815"],
  },
  {
    name: "重庆医科大学附属儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170L1A4",
    position: ["106.554387", "29.556188"],
  },
  {
    name: "中国人民解放军陆军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500106",
    adname: "沙坪坝区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170914B",
    position: ["106.452226", "29.536801"],
  },
  {
    name: "中国人民解放军陆军军医大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "050100",
    id: "B0FFFAJWEQ",
    position: ["106.525912", "29.542336"],
  },
  {
    name: "中国人民解放军陆军特色医学中心",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00178XIWO",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "070000",
    id: "B0FFGBW81E",
    position: ["106.505875", "29.537486"],
  },
  {
    name: "重庆医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B001791T1I",
    position: ["106.574647", "29.560163"],
  },
  {
    name: "四川大学华西医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510107",
    adname: "武侯区",
    citycode: "028",
    cityname: "成都市",
    typecode: "090101",
    id: "B001C05YG2",
    position: ["104.060469", "30.641522"],
  },
  {
    name: "四川省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0IB2L08GG",
    pcode: "510000",
    pname: "四川省",
    adcode: "510105",
    adname: "青羊区",
    citycode: "028",
    cityname: "成都市",
    typecode: "090101",
    id: "B001C8VGCI",
    position: ["104.040057", "30.662931"],
  },
  {
    name: "中国人民解放军西部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510106",
    adname: "金牛区",
    citycode: "028",
    cityname: "成都市",
    typecode: "190301",
    id: "BZA9N503F4",
    position: ["104.114519", "30.746632"],
  },
  {
    name: "中国人民解放军西部战区空军医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510107",
    adname: "武侯区",
    citycode: "028",
    cityname: "成都市",
    typecode: "120302",
    id: "B001C09HNC",
    position: ["104.058078", "30.615245"],
  },
  {
    name: "贵州医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520103",
    adname: "云岩区",
    citycode: "0851",
    cityname: "贵阳市",
    typecode: "141201",
    id: "B0FFG38B3H",
    position: ["106.715199", "26.598190"],
  },
  {
    name: "贵州省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520102",
    adname: "南明区",
    citycode: "0851",
    cityname: "贵阳市",
    typecode: "090101",
    id: "B035300VC0",
    position: ["106.722864", "26.576794"],
  },
  {
    name: "遵义医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520303",
    adname: "汇川区",
    citycode: "0852",
    cityname: "遵义市",
    typecode: "090101",
    id: "B035400HOB",
    position: ["106.945268", "27.706648"],
  },
  {
    name: "昆明医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530102",
    adname: "五华区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "090101",
    id: "B036702A3E",
    position: ["102.693049", "25.042449"],
  },
  {
    name: "昆明医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530112",
    adname: "西山区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "141206",
    id: "B0FFHERQS6",
    position: ["102.680211", "25.026262"],
  },
  {
    name: "云南省第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530102",
    adname: "五华区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "190301",
    id: "B0JU9ZD347",
    position: ["102.708531", "25.033377"],
  },
  {
    name: "昆明市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530103",
    adname: "盘龙区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "090101",
    id: "B03670Z5UW",
    position: ["102.736568", "25.091715"],
  },
  {
    name: "云南省阜外心血管病医院",
    type: "三级甲等",
    level: "专科医院（心血管病）",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530102",
    adname: "五华区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "160121",
    id: "B03670VXZL",
    position: ["102.667395", "25.243982"],
  },
  {
    name: "昆明市延安医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530103",
    adname: "盘龙区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "090101",
    id: "B036702A1F",
    position: ["102.730106", "25.042532"],
  },
  {
    name: "西藏自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "540000",
    pname: "西藏自治区",
    adcode: "540102",
    adname: "城关区",
    citycode: "0891",
    cityname: "拉萨市",
    typecode: "090101",
    id: "B0FFFX8HQY",
    position: ["91.127083", "29.656341"],
  },
  {
    name: "西安交通大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610113",
    adname: "雁塔区",
    citycode: "029",
    cityname: "西安市",
    typecode: "090101",
    id: "B001D08AKU",
    position: ["108.936741", "34.219569"],
  },
  {
    name: "陕西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610103",
    adname: "碑林区",
    citycode: "029",
    cityname: "西安市",
    typecode: "190301",
    id: "B0FFH6ITJW",
    position: ["108.928750", "34.241136"],
  },
  {
    name: "中国人民解放军空军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610102",
    adname: "新城区",
    citycode: "029",
    cityname: "西安市",
    typecode: "110105",
    id: "B0FFFFSUU7",
    position: ["108.988205", "34.270005"],
  },
  {
    name: "兰州大学第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620102",
    adname: "城关区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "090101",
    id: "B03A306A70",
    position: ["103.869288", "36.045769"],
  },
  {
    name: "兰州大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620102",
    adname: "城关区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "090101",
    id: "B03A3032B3",
    position: ["103.812965", "36.061106"],
  },
  {
    name: "甘肃省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620102",
    adname: "城关区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "090101",
    id: "B03A30AKI4",
    position: ["103.867220", "36.048550"],
  },
  {
    name: "中国人民解放军联勤保障部队第九四〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620103",
    adname: "七里河区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "190301",
    id: "B0FFGRNU3F",
    position: ["103.785285", "36.066813"],
  },
  {
    name: "青海省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "630000",
    pname: "青海省",
    adcode: "630102",
    adname: "城东区",
    citycode: "0971",
    cityname: "西宁市",
    typecode: "090101",
    id: "B03CB06K0N",
    position: ["101.803480", "36.619875"],
  },
  {
    name: "青海大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "630000",
    pname: "青海省",
    adcode: "630104",
    adname: "城西区",
    citycode: "0971",
    cityname: "西宁市",
    typecode: "090101",
    id: "B0FFFQ1X4R",
    position: ["101.763392", "36.623021"],
  },
  {
    name: "青海省心脑血管病专科医院",
    type: "三级甲等",
    level: "专科医院（心脑血管病）",
    parent: "",
    pcode: "630000",
    pname: "青海省",
    adcode: "630104",
    adname: "城西区",
    citycode: "0971",
    cityname: "西宁市",
    typecode: "190301",
    id: "B0IA34HNEK",
    position: ["101.662732", "36.649849"],
  },
  {
    name: "宁夏医科大学总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "640000",
    pname: "宁夏回族自治区",
    adcode: "640104",
    adname: "兴庆区",
    citycode: "0951",
    cityname: "银川市",
    typecode: "190700",
    id: "B0HGTZ78S3",
    position: ["106.278714", "38.432015"],
  },
  {
    name: "宁夏回族自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B03B703QBU",
    pcode: "640000",
    pname: "宁夏回族自治区",
    adcode: "640106",
    adname: "金凤区",
    citycode: "0951",
    cityname: "银川市",
    typecode: "090101",
    id: "B0FFG4RB4Q",
    position: ["106.253409", "38.503823"],
  },
  {
    name: "新疆医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650104",
    adname: "新市区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "141201",
    id: "B03DF066N4",
    position: ["87.579571", "43.836944"],
  },
  {
    name: "新疆维吾尔自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650102",
    adname: "天山区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "090101",
    id: "B03DF04GDA",
    position: ["87.613961", "43.783896"],
  },
  {
    name: "中国人民解放军新疆军区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650103",
    adname: "沙依巴克区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "120302",
    id: "B03DF06OF2",
    position: ["87.584244", "43.828361"],
  },
];

export const kidneyData = [
  {
    name: "北京协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110101",
    adname: "东城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A82Z2N",
    position: ["116.415768", "39.912682"],
  },
  {
    name: "北京医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110101",
    adname: "东城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A52E0B",
    position: ["116.415057", "39.903772"],
  },
  {
    name: "中日友好医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110105",
    adname: "朝阳区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A8370S",
    position: ["116.426883", "39.974097"],
  },
  {
    name: "北京大学第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A7CNNE",
    position: ["116.380745", "39.931911"],
  },
  {
    name: "北京大学人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A5BA25",
    position: ["116.354215", "39.936961"],
  },
  {
    name: "北京大学第三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A7CGSS",
    position: ["116.360010", "39.982728"],
  },
  {
    name: "北京大学国际医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B000A887DZ",
    pcode: "110000",
    pname: "北京市",
    adcode: "110114",
    adname: "昌平区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090100",
    id: "B000A9Q0A3",
    position: ["116.263513", "40.101167"],
  },
  {
    name: "首都医科大学宣武医院",
    type: "三级甲等",
    level: "综合医院（侧重神经科学等）",
    parent: "B000A844ET",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A3EA6A",
    position: ["116.362275", "39.891298"],
  },
  {
    name: "首都医科大学附属北京朝阳医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110105",
    adname: "朝阳区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A845NT",
    position: ["116.453397", "39.925878"],
  },
  {
    name: "首都医科大学附属北京友谊医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110102",
    adname: "西城区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A11DA0",
    position: ["116.392125", "39.885153"],
  },
  {
    name: "清华大学附属北京清华长庚医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110114",
    adname: "昌平区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090100",
    id: "B0FFF4BCKE",
    position: ["116.414943", "40.072551"],
  },
  {
    name: "中国人民解放军总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B000A9DPJ4",
    position: ["116.276578", "39.904572"],
  },
  {
    name: "中国人民解放军空军特色医学中心",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "B000A8VUX1",
    pcode: "110000",
    pname: "北京市",
    adcode: "110108",
    adname: "海淀区",
    citycode: "010",
    cityname: "北京市",
    typecode: "090101",
    id: "B0FFLHMWBQ",
    position: ["116.302098", "39.920315"],
  },
  {
    name: "天津市第一中心医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "120000",
    pname: "天津市",
    adcode: "120104",
    adname: "南开区",
    citycode: "022",
    cityname: "天津市",
    typecode: "090101",
    id: "B0016063Z3",
    position: ["117.151896", "39.101076"],
  },
  {
    name: "河北医科大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "130000",
    pname: "河北省",
    adcode: "130105",
    adname: "新华区",
    citycode: "0311",
    cityname: "石家庄市",
    typecode: "090101",
    id: "B0137063F3",
    position: ["114.482117", "38.058064"],
  },
  {
    name: "中国人民解放军联勤保障部队北戴河康复疗养中心",
    type: "三级甲等",
    level: "疗养康复机构（兼具一定医疗能力）",
    parent: "",
    pcode: "130000",
    pname: "河北省",
    adcode: "130304",
    adname: "北戴河区",
    citycode: "0335",
    cityname: "秦皇岛市",
    typecode: "080402",
    id: "B0FFHWMUUK",
    position: ["119.436487", "39.807535"],
  },
  {
    name: "山西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140106",
    adname: "迎泽区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "190301",
    id: "BZCNP101UF",
    position: ["112.580674", "37.845995"],
  },
  {
    name: "山西省第二人民医院",
    type: "三级甲等",
    level: "综合医院（可能侧重肾病等部分专科）",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140106",
    adname: "迎泽区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090200",
    id: "B015F008D1",
    position: ["112.556525", "37.843484"],
  },
  {
    name: "大同市第三人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140213",
    adname: "平城区",
    citycode: "0352",
    cityname: "大同市",
    typecode: "090101",
    id: "B016000B0U",
    position: ["113.287731", "40.075053"],
  },
  {
    name: "山西白求恩医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "140000",
    pname: "山西省",
    adcode: "140105",
    adname: "小店区",
    citycode: "0351",
    cityname: "太原市",
    typecode: "090101",
    id: "B0FFKYMJBB",
    position: ["112.555225", "37.777302"],
  },
  {
    name: "内蒙古包钢医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "150000",
    pname: "内蒙古自治区",
    adcode: "150203",
    adname: "昆都仑区",
    citycode: "0472",
    cityname: "包头市",
    typecode: "090101",
    id: "B01D80108C",
    position: ["109.824671", "40.652117"],
  },
  {
    name: "中国医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210102",
    adname: "和平区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090101",
    id: "B00180CWQO",
    position: ["123.411350", "41.795344"],
  },
  {
    name: "大连医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210204",
    adname: "沙河口区",
    citycode: "0411",
    cityname: "大连市",
    typecode: "090101",
    id: "B019B0AOZE",
    position: ["121.570231", "38.882761"],
  },
  {
    name: "大连市友谊医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210202",
    adname: "中山区",
    citycode: "0411",
    cityname: "大连市",
    typecode: "090101",
    id: "B019B111J1",
    position: ["121.657033", "38.921100"],
  },
  {
    name: "鞍钢集团总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210302",
    adname: "铁东区",
    citycode: "0412",
    cityname: "鞍山市",
    typecode: "090101",
    id: "B0FFF5TZTT",
    position: ["123.011252", "41.120319"],
  },
  {
    name: "中国人民解放军北部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210103",
    adname: "沈河区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090101",
    id: "B0FFJU0NYC",
    position: ["123.440500", "41.769683"],
  },
  {
    name: "中国人民解放军北部战区空军医院",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "",
    pcode: "210000",
    pname: "辽宁省",
    adcode: "210104",
    adname: "大东区",
    citycode: "024",
    cityname: "沈阳市",
    typecode: "090207",
    id: "B001802FB0",
    position: ["123.472908", "41.793921"],
  },
  {
    name: "吉林大学白求恩第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01AF06SEY",
    pcode: "220000",
    pname: "吉林省",
    adcode: "220104",
    adname: "朝阳区",
    citycode: "0431",
    cityname: "长春市",
    typecode: "090101",
    id: "B01AF127AE",
    position: ["125.304855", "43.877282"],
  },
  {
    name: "哈尔滨医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01C30JIUR",
    pcode: "230000",
    pname: "黑龙江省",
    adcode: "230103",
    adname: "南岗区",
    citycode: "0451",
    cityname: "哈尔滨市",
    typecode: "150904",
    id: "B01C30JIWH",
    position: ["126.650518", "45.764741"],
  },
  {
    name: "哈尔滨医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01C30JJ8F",
    pcode: "230000",
    pname: "黑龙江省",
    adcode: "230103",
    adname: "南岗区",
    citycode: "0451",
    cityname: "哈尔滨市",
    typecode: "050100",
    id: "B0FFIQS9N6",
    position: ["126.619554", "45.694548"],
  },
  {
    name: "复旦大学附属中山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310104",
    adname: "徐汇区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00156TKFX",
    position: ["121.454965", "31.197318"],
  },
  {
    name: "复旦大学附属华山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310106",
    adname: "静安区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155KT4W",
    position: ["121.442932", "31.216111"],
  },
  {
    name: "复旦大学附属儿科医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310112",
    adname: "闵行区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090200",
    id: "B00155KP0U",
    position: ["121.394971", "31.139147"],
  },
  {
    name: "上海市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310109",
    adname: "虹口区",
    citycode: "021",
    cityname: "上海市",
    typecode: "141300",
    id: "B00153A582",
    position: ["121.489273", "31.253352"],
  },
  {
    name: "上海交通大学医学院附属瑞金医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00156QHJ2",
    pcode: "310000",
    pname: "上海市",
    adcode: "310101",
    adname: "黄浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00155L9T9",
    position: ["121.467617", "31.211761"],
  },
  {
    name: "上海交通大学医学院附属仁济医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00155L1WO",
    pcode: "310000",
    pname: "上海市",
    adcode: "310101",
    adname: "黄浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B001570WJ3",
    position: ["121.484270", "31.233971"],
  },
  {
    name: "中国人民解放军海军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310110",
    adname: "杨浦区",
    citycode: "021",
    cityname: "上海市",
    typecode: "140000",
    id: "B0FFJ597NX",
    position: ["121.529259", "31.306753"],
  },
  {
    name: "中国人民解放军海军军医大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "310000",
    pname: "上海市",
    adcode: "310115",
    adname: "浦东新区",
    citycode: "021",
    cityname: "上海市",
    typecode: "090101",
    id: "B00156YLJ4",
    position: ["121.512572", "31.148012"],
  },
  {
    name: "苏州大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFL1OLPA",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320508",
    adname: "姑苏区",
    citycode: "0512",
    cityname: "苏州市",
    typecode: "050100",
    id: "B0FFFCVC0F",
    position: ["120.634399", "31.304555"],
  },
  {
    name: "无锡市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320213",
    adname: "梁溪区",
    citycode: "0510",
    cityname: "无锡市",
    typecode: "090101",
    id: "B01FE0II5H",
    position: ["120.323368", "31.540592"],
  },
  {
    name: "常州市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320402",
    adname: "天宁区",
    citycode: "0519",
    cityname: "常州市",
    typecode: "090101",
    id: "B020701JQM",
    position: ["119.960866", "31.779492"],
  },
  {
    name: "中国人民解放军东部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFJRI5KZ",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320102",
    adname: "玄武区",
    citycode: "025",
    cityname: "南京市",
    typecode: "090101",
    id: "B0FFJRY861",
    position: ["118.808021", "32.040747"],
  },
  {
    name: "南京医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320106",
    adname: "鼓楼区",
    citycode: "025",
    cityname: "南京市",
    typecode: "090101",
    id: "B00190A90H",
    position: ["118.744998", "32.080323"],
  },
  {
    name: "徐州医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "320000",
    pname: "江苏省",
    adcode: "320311",
    adname: "泉山区",
    citycode: "0516",
    cityname: "徐州市",
    typecode: "160104",
    id: "B0FFGX2SW1",
    position: ["117.179368", "34.263154"],
  },
  {
    name: "浙江大学医学院附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B023B0929X",
    position: ["120.177825", "30.255920"],
  },
  {
    name: "浙江大学医学院附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B0FFG8HLTS",
    position: ["120.177439", "30.251172"],
  },
  {
    name: "浙江大学医学院附属邵逸夫医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330102",
    adname: "上城区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B023B051V6",
    position: ["120.202351", "30.256376"],
  },
  {
    name: "浙江大学医学院附属儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330105",
    adname: "拱墅区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090200",
    id: "B023B06XYO",
    position: ["120.165734", "30.260224"],
  },
  {
    name: "温州医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330302",
    adname: "鹿城区",
    citycode: "0577",
    cityname: "温州市",
    typecode: "090101",
    id: "B024103ZQM",
    position: ["120.665318", "28.013566"],
  },
  {
    name: "宁波市医疗中心李惠利东部医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330212",
    adname: "鄞州区",
    citycode: "0574",
    cityname: "宁波市",
    typecode: "100100",
    id: "B0FFI5SAZM",
    position: ["121.562708", "29.853098"],
  },
  {
    name: "宁波市鄞州区第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330212",
    adname: "鄞州区",
    citycode: "0574",
    cityname: "宁波市",
    typecode: "190301",
    id: "B0FFH5APW9",
    position: ["121.552893", "29.808744"],
  },
  {
    name: "树兰（杭州）医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330105",
    adname: "拱墅区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "090101",
    id: "B0FFG6IQYR",
    position: ["120.174522", "30.328786"],
  },
  {
    name: "中国人民解放军联勤保障部队第九〇三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "330000",
    pname: "浙江省",
    adcode: "330106",
    adname: "西湖区",
    citycode: "0571",
    cityname: "杭州市",
    typecode: "120302",
    id: "B0J3MHF2OE",
    position: ["120.116301", "30.246458"],
  },
  {
    name: "中国科学技术大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B022702OL9",
    position: ["117.267662", "31.847151"],
  },
  {
    name: "安徽医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B022702OL9",
    position: ["117.267662", "31.847151"],
  },
  {
    name: "安徽医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "340000",
    pname: "安徽省",
    adcode: "340104",
    adname: "蜀山区",
    citycode: "0551",
    cityname: "合肥市",
    typecode: "090101",
    id: "B02270IHE7",
    position: ["117.212410", "31.790188"],
  },
  {
    name: "福建医科大学附属协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090101",
    id: "B024F01LIN",
    position: ["119.304020", "26.077841"],
  },
  {
    name: "福建省立医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B024F04VN7",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "050100",
    id: "B0GRKU5LSZ",
    position: ["119.299381", "26.084133"],
  },
  {
    name: "厦门大学附属翔安医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "350000",
    pname: "福建省",
    adcode: "350213",
    adname: "翔安区",
    citycode: "0592",
    cityname: "厦门市",
    typecode: "090100",
    id: "B02500QKUL",
    position: ["118.350162", "24.585555"],
  },
  {
    name: "中国人民解放军联勤保障部队第九〇〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0FFJRGU1I",
    pcode: "350000",
    pname: "福建省",
    adcode: "350102",
    adname: "鼓楼区",
    citycode: "0591",
    cityname: "福州市",
    typecode: "090101",
    id: "B0FFK1YGP1",
    position: ["119.280085", "26.092136"],
  },
  {
    name: "江西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "120303",
    id: "B0FFF5OJLQ",
    position: ["115.887725", "28.691942"],
  },
  {
    name: "南昌大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "090101",
    id: "B031702KIL",
    position: ["115.910291", "28.686539"],
  },
  {
    name: "南昌大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "360000",
    pname: "江西省",
    adcode: "360102",
    adname: "东湖区",
    citycode: "0791",
    cityname: "南昌市",
    typecode: "090101",
    id: "B031702VZZ",
    position: ["115.901957", "28.682490"],
  },
  {
    name: "山东大学齐鲁医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B02130WZ7F",
    position: ["117.018773", "36.656637"],
  },
  {
    name: "山东大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B02130WZ7F",
    position: ["117.018773", "36.656637"],
  },
  {
    name: "山东省立医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370104",
    adname: "槐荫区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "080603",
    id: "B0213014FF",
    position: ["116.982434", "36.657591"],
  },
  {
    name: "青岛大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370202",
    adname: "市南区",
    citycode: "0532",
    cityname: "青岛市",
    typecode: "090101",
    id: "B021403HO2",
    position: ["120.327228", "36.067916"],
  },
  {
    name: "山东省千佛山医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370102",
    adname: "历下区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "100105",
    id: "B0FFGQ30FC",
    position: ["117.053823", "36.648165"],
  },
  {
    name: "烟台毓璜顶医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370602",
    adname: "芝罘区",
    citycode: "0535",
    cityname: "烟台市",
    typecode: "090101",
    id: "B021704X7N",
    position: ["121.389746", "37.535769"],
  },
  {
    name: "临沂市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "371302",
    adname: "兰山区",
    citycode: "0539",
    cityname: "临沂市",
    typecode: "090101",
    id: "B021B09FNI",
    position: ["118.350793", "35.063762"],
  },
  {
    name: "潍坊市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370705",
    adname: "奎文区",
    citycode: "0536",
    cityname: "潍坊市",
    typecode: "141202",
    id: "B0218030BR",
    position: ["119.129473", "36.701748"],
  },
  {
    name: "中国人民解放军联勤保障部队第九六〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "370000",
    pname: "山东省",
    adcode: "370105",
    adname: "天桥区",
    citycode: "0531",
    cityname: "济南市",
    typecode: "090101",
    id: "B0FFJXWTMY",
    position: ["116.982558", "36.696924"],
  },
  {
    name: "中国人民解放军海军第九七一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B02140VT8F",
    pcode: "370000",
    pname: "山东省",
    adcode: "370202",
    adname: "市南区",
    citycode: "0532",
    cityname: "青岛市",
    typecode: "071600",
    id: "B0JG3DWGX3",
    position: ["120.386284", "36.071360"],
  },
  {
    name: "郑州大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410103",
    adname: "二七区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0173080HG",
    position: ["113.646042", "34.752734"],
  },
  {
    name: "河南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B01730KBLG",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "061210",
    id: "B0JRFHS5VA",
    position: ["113.681492", "34.771209"],
  },
  {
    name: "郑州人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B017305KKO",
    pcode: "410000",
    pname: "河南省",
    adcode: "410102",
    adname: "中原区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0IAJGR305",
    position: ["113.612287", "34.750256"],
  },
  {
    name: "郑州市第七人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410104",
    adname: "管城回族区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090200",
    id: "B01730K9C6",
    position: ["113.740263", "34.713582"],
  },
  {
    name: "河南中医学院第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "090101",
    id: "B0173046A3",
    position: ["113.674298", "34.759020"],
  },
  {
    name: "中国人民解放军联勤保障部队第九八八医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "410000",
    pname: "河南省",
    adcode: "410105",
    adname: "金水区",
    citycode: "0371",
    cityname: "郑州市",
    typecode: "190301",
    id: "B0IA3GLW9P",
    position: ["113.637196", "34.772961"],
  },
  {
    name: "华中科技大学同济医学院附属同济医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B001B07JBE",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420104",
    adname: "硚口区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B0FFGTQYIR",
    position: ["114.262367", "30.579204"],
  },
  {
    name: "华中科技大学同济医学院附属协和医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420103",
    adname: "江汉区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B001B0IZPF",
    position: ["114.274723", "30.584057"],
  },
  {
    name: "武汉大学人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B001B06652",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420106",
    adname: "武昌区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B001B1C7BQ",
    position: ["114.300585", "30.535761"],
  },
  {
    name: "武汉大学中南医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420111",
    adname: "洪山区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090100|090200",
    id: "B0FFKCKC2N",
    position: ["114.396585", "30.536372"],
  },
  {
    name: "东风公司总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420303",
    adname: "张湾区",
    citycode: "0719",
    cityname: "十堰市",
    typecode: "060400|060100",
    id: "B02CF0QCF8",
    position: ["110.771031", "32.653481"],
  },
  {
    name: "中国人民解放军中部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420106",
    adname: "武昌区",
    citycode: "027",
    cityname: "武汉市",
    typecode: "090101",
    id: "B0FFJS7ZOP",
    position: ["114.344866", "30.531123"],
  },
  {
    name: "宜昌市中心人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "420000",
    pname: "湖北省",
    adcode: "420503",
    adname: "伍家岗区",
    citycode: "0717",
    cityname: "宜昌市",
    typecode: "090101",
    id: "B0FFFGWCLN",
    position: ["111.306907", "30.684410"],
  },
  {
    name: "中南大学湘雅医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430105",
    adname: "开福区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B02DB00B1E",
    position: ["112.984047", "28.212209"],
  },
  {
    name: "中南大学湘雅二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430102",
    adname: "芙蓉区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "100103",
    id: "B02DB0VBWH",
    position: ["113.027619", "28.184897"],
  },
  {
    name: "中南大学湘雅三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430104",
    adname: "岳麓区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B02DB02J64",
    position: ["112.944798", "28.220483"],
  },
  {
    name: "湖南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430102",
    adname: "芙蓉区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090101",
    id: "B0H39RU13M",
    position: ["112.981993", "28.190395"],
  },
  {
    name: "益阳市中心医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430903",
    adname: "赫山区",
    citycode: "0737",
    cityname: "益阳市",
    typecode: "090100",
    id: "B02E1001PC",
    position: ["112.354620", "28.570175"],
  },
  {
    name: "郴州市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "431002",
    adname: "北湖区",
    citycode: "0735",
    cityname: "郴州市",
    typecode: "141201",
    id: "B02DF007E4",
    position: ["113.022814", "25.792694"],
  },
  {
    name: "南华大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430407",
    adname: "石鼓区",
    citycode: "0734",
    cityname: "衡阳市",
    typecode: "190301",
    id: "B0IA3ZSFYS",
    position: ["112.606648", "26.896262"],
  },
  {
    name: "常德市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430702",
    adname: "武陵区",
    citycode: "0736",
    cityname: "常德市",
    typecode: "190301",
    id: "B0FFGD4CIX",
    position: ["111.693638", "29.028146"],
  },
  {
    name: "岳阳市一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0HRSLRM6M",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430602",
    adname: "岳阳楼区",
    citycode: "0730",
    cityname: "岳阳市",
    typecode: "090101",
    id: "B02DA014B2",
    position: ["113.111718", "29.372735"],
  },
  {
    name: "湖南省儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "430000",
    pname: "湖南省",
    adcode: "430111",
    adname: "雨花区",
    citycode: "0731",
    cityname: "长沙市",
    typecode: "090200",
    id: "B02DB03KPH",
    position: ["112.989376", "28.173845"],
  },
  {
    name: "中山大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140226E",
    position: ["113.291159", "23.126327"],
  },
  {
    name: "中山大学附属第三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440106",
    adname: "天河区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B0FFF5UY3V",
    position: ["113.341211", "23.134165"],
  },
  {
    name: "中山大学孙逸仙纪念医院（院本部）",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B0FFH3TURK",
    position: ["113.255889", "23.109552"],
  },
  {
    name: "中山大学附属第五医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440402",
    adname: "香洲区",
    citycode: "0756",
    cityname: "珠海市",
    typecode: "090101",
    id: "B02F400ISX",
    position: ["113.573455", "22.298425"],
  },
  {
    name: "广东省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140UERJ",
    position: ["113.287272", "23.125087"],
  },
  {
    name: "广东省第二人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "100104",
    id: "B0014043F6",
    position: ["113.335513", "23.095585"],
  },
  {
    name: "南方医科大学南方医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440111",
    adname: "白云区",
    citycode: "020",
    cityname: "广州市",
    typecode: "100103",
    id: "B00140CK8U",
    position: ["113.329537", "23.187643"],
  },
  {
    name: "南方医科大学珠江医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140C1IU",
    position: ["113.268936", "23.081055"],
  },
  {
    name: "广州医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "141300",
    id: "B00140BBHL",
    position: ["113.262511", "23.112177"],
  },
  {
    name: "广州医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440105",
    adname: "海珠区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140UGDV",
    position: ["113.278609", "23.089880"],
  },
  {
    name: "广州医科大学附属第三医院",
    type: "三级甲等",
    level: "综合按医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440103",
    adname: "荔湾区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140I6VS",
    position: ["113.234177", "23.117800"],
  },
  {
    name: "广东省中医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00140U136",
    position: ["113.256550", "23.118113"],
  },
  {
    name: "暨南大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440106",
    adname: "天河区",
    citycode: "020",
    cityname: "广州市",
    typecode: "070000",
    id: "B0J2TD3X8B",
    position: ["113.350217", "23.127149"],
  },
  {
    name: "深圳市第三人民医院",
    type: "三级甲等",
    level: "综合医院（传染病等特色）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440307",
    adname: "龙岗区",
    citycode: "0755",
    cityname: "深圳市",
    typecode: "090101",
    id: "B02F38JF90",
    position: ["114.128750", "22.635393"],
  },
  {
    name: "中山市人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "442000",
    adname: "中山市",
    citycode: "0760",
    cityname: "中山市",
    typecode: "090101",
    id: "B02F8008N5",
    position: ["113.383715", "22.525426"],
  },
  {
    name: "佛山市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440604",
    adname: "禅城区",
    citycode: "0757",
    cityname: "佛山市",
    typecode: "090101",
    id: "B02F501YDX",
    position: ["113.124749", "23.006370"],
  },
  {
    name: "中国人民解放军南部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "090101",
    id: "B00141RGH5",
    position: ["113.254855", "23.142608"],
  },
  {
    name: "中国人民解放军南部战区空军医院",
    type: "三级甲等",
    level: "综合医院（空军特色相关医疗）",
    parent: "",
    pcode: "440000",
    pname: "广东省",
    adcode: "440104",
    adname: "越秀区",
    citycode: "020",
    cityname: "广州市",
    typecode: "120302",
    id: "B0FFF3V087",
    position: ["113.314114", "23.132953"],
  },
  {
    name: "广西壮族自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450103",
    adname: "青秀区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B030300767",
    position: ["108.327964", "22.802248"],
  },
  {
    name: "广西医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450103",
    adname: "青秀区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B03030044A",
    position: ["108.343151", "22.795874"],
  },
  {
    name: "广西医科大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450107",
    adname: "西乡塘区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B0FFHLD7SC",
    position: ["108.259811", "22.841281"],
  },
  {
    name: "广西中医药大学附属瑞康医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450102",
    adname: "兴宁区",
    citycode: "0771",
    cityname: "南宁市",
    typecode: "090101",
    id: "B0303056U6",
    position: ["108.314762", "22.821812"],
  },
  {
    name: "中国人民解放军联勤保障部队第九二四医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450304",
    adname: "象山区",
    citycode: "0773",
    cityname: "桂林市",
    typecode: "090101",
    id: "B0FFJX8ZRE",
    position: ["110.293958", "25.265328"],
  },
  {
    name: "中国人民解放军联勤保障部队第九二三医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "450000",
    pname: "广西壮族自治区",
    adcode: "450203",
    adname: "鱼峰区",
    citycode: "0772",
    cityname: "柳州市",
    typecode: "130104",
    id: "B03040OIG0",
    position: ["109.429093", "24.282416"],
  },
  {
    name: "海南医学院第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "460000",
    pname: "海南省",
    adcode: "460106",
    adname: "龙华区",
    citycode: "0898",
    cityname: "海口市",
    typecode: "090101",
    id: "B03820OY4N",
    position: ["110.300158", "19.979264"],
  },
  {
    name: "海南省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "460000",
    pname: "海南省",
    adcode: "460105",
    adname: "秀英区",
    citycode: "0898",
    cityname: "海口市",
    typecode: "090101",
    id: "B03820OIDK",
    position: ["110.287710", "20.004771"],
  },
  {
    name: "重庆医科大学附属第一医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170L57Q",
    position: ["106.512621", "29.530815"],
  },
  {
    name: "重庆医科大学附属儿童医院",
    type: "三级甲等",
    level: "专科医院（儿科）",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170L1A4",
    position: ["106.554387", "29.556188"],
  },
  {
    name: "中国人民解放军陆军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500106",
    adname: "沙坪坝区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B00170914B",
    position: ["106.452226", "29.536801"],
  },
  {
    name: "中国人民解放军陆军军医大学第二附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "050100",
    id: "B0FFFAJWEQ",
    position: ["106.525912", "29.542336"],
  },
  {
    name: "中国人民解放军陆军特色医学中心",
    type: "三级甲等",
    level: "综合医院",
    parent: "B00178XIWO",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "070000",
    id: "B0FFGBW81E",
    position: ["106.505875", "29.537486"],
  },
  {
    name: "重庆医科大学附属第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "500000",
    pname: "重庆市",
    adcode: "500103",
    adname: "渝中区",
    citycode: "023",
    cityname: "重庆市",
    typecode: "090101",
    id: "B001791T1I",
    position: ["106.574647", "29.560163"],
  },
  {
    name: "四川大学华西医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510107",
    adname: "武侯区",
    citycode: "028",
    cityname: "成都市",
    typecode: "090101",
    id: "B001C05YG2",
    position: ["104.060469", "30.641522"],
  },
  {
    name: "四川省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B0IB2L08GG",
    pcode: "510000",
    pname: "四川省",
    adcode: "510105",
    adname: "青羊区",
    citycode: "028",
    cityname: "成都市",
    typecode: "090101",
    id: "B001C8VGCI",
    position: ["104.040057", "30.662931"],
  },
  {
    name: "中国人民解放军西部战区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510106",
    adname: "金牛区",
    citycode: "028",
    cityname: "成都市",
    typecode: "190301",
    id: "BZA9N503F4",
    position: ["104.114519", "30.746632"],
  },
  {
    name: "中国人民解放军西部战区空军医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "510000",
    pname: "四川省",
    adcode: "510107",
    adname: "武侯区",
    citycode: "028",
    cityname: "成都市",
    typecode: "120302",
    id: "B001C09HNC",
    position: ["104.058078", "30.615245"],
  },
  {
    name: "贵州医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520103",
    adname: "云岩区",
    citycode: "0851",
    cityname: "贵阳市",
    typecode: "141201",
    id: "B0FFG38B3H",
    position: ["106.715199", "26.598190"],
  },
  {
    name: "贵州省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520102",
    adname: "南明区",
    citycode: "0851",
    cityname: "贵阳市",
    typecode: "090101",
    id: "B035300VC0",
    position: ["106.722864", "26.576794"],
  },
  {
    name: "遵义医科大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "520000",
    pname: "贵州省",
    adcode: "520303",
    adname: "汇川区",
    citycode: "0852",
    cityname: "遵义市",
    typecode: "090101",
    id: "B035400HOB",
    position: ["106.945268", "27.706648"],
  },
  {
    name: "昆明医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530102",
    adname: "五华区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "090101",
    id: "B036702A3E",
    position: ["102.693049", "25.042449"],
  },
  {
    name: "昆明市第一人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "530000",
    pname: "云南省",
    adcode: "530103",
    adname: "盘龙区",
    citycode: "0871",
    cityname: "昆明市",
    typecode: "090101",
    id: "B03670Z5UW",
    position: ["102.736568", "25.091715"],
  },
  {
    name: "西藏自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "540000",
    pname: "西藏自治区",
    adcode: "540102",
    adname: "城关区",
    citycode: "0891",
    cityname: "拉萨市",
    typecode: "090101",
    id: "B0FFFX8HQY",
    position: ["91.127083", "29.656341"],
  },
  {
    name: "西安交通大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610113",
    adname: "雁塔区",
    citycode: "029",
    cityname: "西安市",
    typecode: "090101",
    id: "B001D08AKU",
    position: ["108.936741", "34.219569"],
  },
  {
    name: "陕西省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610103",
    adname: "碑林区",
    citycode: "029",
    cityname: "西安市",
    typecode: "190301",
    id: "B0FFH6ITJW",
    position: ["108.928750", "34.241136"],
  },
  {
    name: "中国人民解放军空军军医大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "610000",
    pname: "陕西省",
    adcode: "610102",
    adname: "新城区",
    citycode: "029",
    cityname: "西安市",
    typecode: "110105",
    id: "B0FFFFSUU7",
    position: ["108.988205", "34.270005"],
  },
  {
    name: "兰州大学第二医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620102",
    adname: "城关区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "090101",
    id: "B03A3032B3",
    position: ["103.812965", "36.061106"],
  },
  {
    name: "甘肃省人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620102",
    adname: "城关区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "090101",
    id: "B03A30AKI4",
    position: ["103.867220", "36.048550"],
  },
  {
    name: "中国人民解放军联勤保障部队第九四〇医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "620000",
    pname: "甘肃省",
    adcode: "620103",
    adname: "七里河区",
    citycode: "0931",
    cityname: "兰州市",
    typecode: "190301",
    id: "B0FFGRNU3F",
    position: ["103.785285", "36.066813"],
  },
  {
    name: "青海大学附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "630000",
    pname: "青海省",
    adcode: "630104",
    adname: "城西区",
    citycode: "0971",
    cityname: "西宁市",
    typecode: "090101",
    id: "B0FFFQ1X4R",
    position: ["101.763392", "36.623021"],
  },
  {
    name: "宁夏回族自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "B03B703QBU",
    pcode: "640000",
    pname: "宁夏回族自治区",
    adcode: "640106",
    adname: "金凤区",
    citycode: "0951",
    cityname: "银川市",
    typecode: "090101",
    id: "B0FFG4RB4Q",
    position: ["106.253409", "38.503823"],
  },
  {
    name: "新疆医科大学第一附属医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650104",
    adname: "新市区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "141201",
    id: "B03DF066N4",
    position: ["87.579571", "43.836944"],
  },
  {
    name: "新疆维吾尔自治区人民医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650102",
    adname: "天山区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "090101",
    id: "B03DF04GDA",
    position: ["87.613961", "43.783896"],
  },
  {
    name: "中国人民解放军新疆军区总医院",
    type: "三级甲等",
    level: "综合医院",
    parent: "",
    pcode: "650000",
    pname: "新疆维吾尔自治区",
    adcode: "650103",
    adname: "沙依巴克区",
    citycode: "0991",
    cityname: "乌鲁木齐市",
    typecode: "120302",
    id: "B03DF06OF2",
    position: ["87.584244", "43.828361"],
  },
];
